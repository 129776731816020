import React from 'react'
import { Section } from '@shared/components/section'
import { ExternalPaths } from '@shared/menu-items/paths'
import NewSkolkovo from '@shared/svg/new-skolkovo.svg'

import styles from './style.module.scss'

export const BlockDisclaimerCentralBank = () => {
	return (
		<Section fullWidth className={styles.section}>
			<div className={styles.disclaimer}>
				<div>
					<div className={styles.images}>
						<a href={ExternalPaths.skolkovo} target='_blank' rel='noopener noreferrer nofollow'>
							<NewSkolkovo />
						</a>
					</div>
					<p>
						ООО «Кредит.Клаб» — участник инновационного центра «Сколково». Мы предоставляем цифровой
						сервис для удобного получения кредитов (
						<a
							href='https://creditclub.tech/'
							target='_blank'
							className={styles.details}
							rel='noopener noreferrer nofollow'
						>
							более&nbsp;подробно
						</a>
						).
					</p>
				</div>
				<p>
					Кредитором по кредитному договору будет являться кредитная организация-партнёр, принявшая
					решение о выдаче кредита. Сервис цифровых решений не является деятельностью оператора
					инвестиционной платформы по содействию в инвестировании и в предоставлении займов с
					использованием инвестиционной платформы.
				</p>
			</div>
		</Section>
	)
}
