import { PageSettings } from '@http/api-models/page-settings'
import { NextPageMixedProps } from '@shared/models/next-fc'
import { parse } from 'cookie'
import { GetServerSideProps } from 'next'

import { getPromoPages } from '../admin/http'
import { DEFAULT_CITY } from '../regions/list'
import { RegionalPagesPath, regionalPagesProps } from '../regions/runtime-components/pages-props'
import { RuntimeComponentProps } from '../regions/runtime-components/types'
import {
	getAppliedCityFromCookies,
	getDetectedCityFromCookies,
	serverSideRegionRedirectFactory,
} from '../regions/utils'
import { MainPageV2 } from '../screens/main-page-v2'
import { getSavingsParams } from '../ssr-resolvers/savings-params'

const Resolver: NextPageMixedProps<RuntimeComponentProps, any> = (props) =>
	MainPageV2({ rootH1Text: 'Кредит под залог квартиры на любые цели', city: props.city })

Resolver.headProps = regionalPagesProps[RegionalPagesPath.root].headProps
Resolver.layout = regionalPagesProps[RegionalPagesPath.root].layout

export default Resolver

export const getServerSideProps: GetServerSideProps<RuntimeComponentProps> = async (context) => {
	const cookies = parse(context.req.headers?.cookie || '')
	let city = getAppliedCityFromCookies(cookies)

	if (city) {
		city = getDetectedCityFromCookies(cookies) || DEFAULT_CITY
		return serverSideRegionRedirectFactory(city, '')
	}

	const props: { pageSettings: PageSettings } & RuntimeComponentProps = { pageSettings: {} }

	try {
		const [savings, promoPages] = await Promise.allSettled([getSavingsParams(), getPromoPages()])

		if (savings.status === 'fulfilled') {
			props.pageSettings.savings = {
				params: savings.value,
			}
		}

		if (promoPages.status === 'fulfilled') {
			props.pageSettings.admin = {
				promoPages: promoPages.value,
			}
		}
	} catch {
		// silence
	}

	props.regionalPagePath = RegionalPagesPath.root

	props.city = city

	return {
		props,
	}
}
